import { graphLoginRequest } from './AuthConfig';
import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';

export interface IProfile {
    photo?: Blob,
    displayName: string,
    email: string,
}

export class GraphService {

    //Important: When using Graph or acquiring a token, put the code away from your UI when you can
    private static async getAccessToken(instance: IPublicClientApplication, accounts: AccountInfo[]): Promise<string> {
        const account = instance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }
    
        // Important: do not catch InteractionRequiredAuthError, let the front-end handle it
        const authCall = await instance.acquireTokenSilent({
            ...graphLoginRequest,
            account: account
        });

        return authCall.accessToken;
    }
        
    private static async getProfilePicture(accessToken: string): Promise<Blob> {
        try {
            const headers = new Headers();
            const bearer = `Bearer ${accessToken}`;

            headers.append("Authorization", bearer);

            const options = {
                method: "GET",
                headers: headers
            };

            const res = await fetch('https://graph.microsoft.com/v1.0/me/photos/48x48/$value', options);
            const blobRes = await res.blob();
            return blobRes;
        }
        catch (err) {
            console.error(err);
            return {} as Blob;
        }
    }

    private static async getGraphProfile(accessToken: string): Promise<any> {
        try {
            const headers = new Headers();
            const bearer = `Bearer ${accessToken}`;

            headers.append("Authorization", bearer);

            const options = {
                method: "GET",
                headers: headers
            };

            const res = await fetch('https://graph.microsoft.com/v1.0/me', options);
            const jsonRes = await res.json();
            return jsonRes;
        }
        catch (err) {
            console.error(err);
        }
    }

    public static async GetProfile(instance: IPublicClientApplication, accounts: AccountInfo[]): Promise<IProfile> {

        const accessToken = await this.getAccessToken(instance, accounts);

        const data = await this.getGraphProfile(accessToken);

        const profile: IProfile = {
            photo: await this.getProfilePicture(accessToken),
            displayName: data?.displayName,
            email: data?.mail
        };
        
        return profile;
    }
}