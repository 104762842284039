import { createContext } from "react"
import { IExtendedEmployee } from "../models/IEmployee";

type ImpersonatedUserType = {
    impersonatedUser: IExtendedEmployee | null
    setImpersonatedUser: React.Dispatch<React.SetStateAction<IExtendedEmployee | null>>
}

const impersonatedUserState: ImpersonatedUserType = {
    impersonatedUser : null,
    setImpersonatedUser: () => {}
}

export const ImpersonatedUserContext = createContext<ImpersonatedUserType>(impersonatedUserState);