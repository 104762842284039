import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Select, Box, MenuItem, Container } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import useFetchWithMsal from "../hooks/useFetchWithMsal";
import { forecastServerLoginRequest } from "../util/AuthConfig";

import { IEACReport } from '../models/IEACReport';
import Loading from "../components/Loading";
import ErrorPage from "./Error";
import eacStyles from '../styles/project-styles.module.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React from "react";

export const EAC = () => {
    const { id } = useParams();
    const { execute } = useFetchWithMsal({ scopes: forecastServerLoginRequest.scopes });
    const { instance, inProgress } = useMsal(); 
    const queryClient = useQueryClient();
    const [selectedDate, setSelectedDate] = useState<string>("");

    // Method with initial request to get EAC report with passed in projectId 
    const useEAC = () => {
        return useQuery<IEACReport>({
            queryKey: ['eac', { instance, inProgress, id, selectedDate }],
            queryFn: async ({ queryKey }) => {
                const [_key, { instance, id, selectedDate }] = queryKey as any
                let data = {
                    ProjectId: id,
                    EACDate: selectedDate
                }
                const report: IEACReport | null = await execute<IEACReport | null>('POST', '../api/Home/EACReport', data);
                if (!report) {
                    throw new Error("Could not retrieve EAC Report");
                }
                console.log(report);
                return report;
            },
            refetchOnWindowFocus: false, // keeps the query from refetching if you switch tabs
        })
    }

    //It is marked as stale.This stale state overrides any staleTime configurations being used in useQuery or related hooks
    //If the query is currently being rendered via useQuery or related hooks, it will also be refetched in the background
    const useMutateEACReport = useMutation({
        mutationFn: async (date: string) => await execute<IEACReport | null>('POST', '../api/Home/EACReport', { ProjectId: id, EACDate: date }),
        onSuccess: (data: IEACReport | null, variables: string) => {
            queryClient.setQueryData(['eac', { instance, inProgress, id, selectedDate }], data);
        }
    })

    const { data, isPending, isFetching, error } = useEAC();

    if (isPending || isFetching) {
        return <Loading paddingValue={4} />
    }

    if (error) {
        return <ErrorPage error={error} />
    }

    const changeEACReport = async (date: string) => {
        setSelectedDate(date);
        useMutateEACReport.mutateAsync(selectedDate);
    }

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
            {data.eacDatesAvailable.length > 0 ?
                <>
                <Box>
                    <Typography variant='h4' sx={{ paddingTop:2 }}>
                        {`EAC Report: ${data?.projectName}`}
                    </Typography>
                </Box>
                    <EnhancedTable forecastByRole={data?.forecastByRole} eacReport={data} changeEACReport={changeEACReport} />
                </>
                :
                <Typography variant='h4' sx={{ paddingTop: 2 }}> No EAC Dates Available</Typography>
            }
        </>
    )
}

export default EAC;

interface ITableProps {
    forecastByRole: Record<string, string[]> | undefined;
    eacReport: IEACReport | undefined;
    changeEACReport: (date: string) => void;
}

export const EnhancedTable = (props: ITableProps) => {
    const { forecastByRole, eacReport, changeEACReport } = props;
    const [prevEACTotal, setPrevEACTotal] = useState<number>(0.00);
    const [currEACTotal, setCurrEACTotal] = useState<number>(0.00);
    const [budgetedTotal, setBudgetedTotall] = useState<number>(0.00);
    const [burnedTotal, setBurnedTotal] = useState<number>(0.00);
    const [forecastedTotal, setForecastedTotal] = useState<number>(0.00);
    const [overUnderTotal, setOverUnderTotal] = useState<number>(0.00);
    const [overUnderTotalColor, setOverUnderTotalColor] = useState<string>("black");
    const [deltaTotal, setDeltaTotal] = useState<number>(0.00);
    const [selectedDate, setSelectedDate] = useState<string>(eacReport?.prevEACDate ?? "");


    const calculateTotals = (forecastByRole: Record<string, string[]>) => {
        let prevEAC: number = 0.00;
        let currEAC: number = 0.00;
        let budgeted = 0.00;
        let burned = 0.00;
        let forecasted = 0.00;
        let overUnder = 0.00;
        let delta: number = 100.00;
        Object.keys(forecastByRole).map((role: string) => {
            forecastByRole[role].map((forecastName: string) => {
                prevEAC += Number(eacReport?.eacReportLines[forecastName].prevEAC ?? 0.00);
                currEAC += Number(eacReport?.eacReportLines[forecastName].currEAC ?? 0.00);
                budgeted += Number(eacReport?.eacReportLines[forecastName].budgetedHours ?? 0.00);
                burned += Number(eacReport?.eacReportLines[forecastName].burnedHours ?? 0.00);
                forecasted += Number(eacReport?.eacReportLines[forecastName].forecastedHours ?? 0.00);
                overUnder += Number(eacReport?.eacReportLines[forecastName].budgetOverUnder ?? 0.00);
            })
        })
        if (prevEAC != 0) delta = Number(((currEAC - prevEAC) / prevEAC * 100).toFixed(2));
        setPrevEACTotal(prevEAC);
        setCurrEACTotal(currEAC);
        setBudgetedTotall(budgeted);
        setBurnedTotal(burned);
        setForecastedTotal(forecasted);
        setOverUnderTotalColor(overUnder < 0 ? "red" : "black")
        setOverUnderTotal(overUnder);
        setDeltaTotal(delta);
    } 

    useEffect(() => {
        if (forecastByRole) calculateTotals(forecastByRole);
    }, [eacReport]);

    const handleDateChange = async (date: string) => {
        setSelectedDate(date);
        changeEACReport(date);
    }
    return (
        <TableContainer id="eac-container" sx={{ paddingLeft: 0, paddingRight: 0, borderRight: "1px solid grey", marginTop: 2, boxShadow: 5 }}>
            <Table id="eac-report-table" sx={{ minWidth: 500, borderCollapse: "collapse", paddingLeft: 0, paddingRight: 0, border: "1px solid grey" }} stickyHeader>
                <TableHead>
                    {/*Header Row*/}
                    <TableRow key={"trh-1"} sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#e5e5e5", color: "#fff" }}>
                        <TableCell colSpan={2} sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#e5e5e5" }}></TableCell>
                        <TableCell colSpan={3} sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#e5e5e5", fontWeight: "bold" }} align={ "center"}>Estimated At Completion</TableCell>
                        <TableCell colSpan={4} sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#e5e5e5", fontWeight: "bold" }} align={"center"}>Current EAC Details</TableCell>
                    </TableRow>
                    {/*Header Row*/}
                    <TableRow key={"trh-2"}>
                        <TableCell sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#6A6867", color: "#fff", fontWeight: "bold" }}>Role</TableCell>
                        <TableCell sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#6A6867", color: "#fff", fontWeight: "bold" }} >Resource</TableCell>
                        <TableCell sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#6A6867", color: "#fff", fontWeight: "bold" }} align={"center"}>Previous EAC<br />
                            <Select
                                labelId="demo-simple-select-label"
                                id="previousEACSelection"
                                onChange={(event) => { handleDateChange(event.target.value) }}
                                value={selectedDate}
                                defaultValue={""}
                                sx={{ maxMenuHeight: "200", lineHeight: "1.25", backgroundColor: "#fff", padding: "1.25 5px" }}
                            >
                                {
                                    eacReport?.eacDatesAvailable.map((date: string, index: number) => {
                                        if (index !== 0) return (<MenuItem key={date} value={date} selected>{`${date.substring(0, 4)}-${date.substring(4, 6)}-${date.substring(6, 8)}`}</MenuItem> )
                                        return null;
                                    })
                                }
                            </Select>
                        </TableCell>
                        <TableCell sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#6A6867", color: "#fff" }} align={"center"}>EAC for <br />{`${eacReport?.eacDatesAvailable[0].substring(0, 4)}-${eacReport?.eacDatesAvailable[0].substring(4, 6)}-${eacReport?.eacDatesAvailable[0].substring(6, 8)}`}</TableCell>
                        <TableCell sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#6A6867", color: "#fff", fontWeight: "bold" }} align={ "center"}>Delta</TableCell>
                        <TableCell sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#6A6867", color: "#fff", fontWeight: "bold" }} align={ "center"}>Budgeted</TableCell>
                        <TableCell sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#6A6867", color: "#fff", fontWeight: "bold" }} align={ "center"}>Burned</TableCell>
                        <TableCell sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#6A6867", color: "#fff", fontWeight: "bold" }} align={ "center"}>Forecasted</TableCell>
                        <TableCell sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#6A6867", color: "#fff", fontWeight: "bold" }} align={"center"}>Budget <br />(Over/Under)</TableCell>
                    </TableRow>
                    {/*Totals Row*/}
                    <TableRow key={"trh-3"}>
                        <TableCell colSpan={2} sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#e5e5e5" }}>Totals</TableCell>
                        <TableCell sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#e5e5e5", fontWeight: "bold" }} align={"right"}>{prevEACTotal.toFixed(2)}</TableCell>
                        <TableCell sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#e5e5e5", fontWeight: "bold" }} align={"right"}>{currEACTotal.toFixed(2)}</TableCell>
                        <TableCell sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#e5e5e5", fontWeight: "bold" }} align={"right"}>{deltaTotal}%</TableCell>
                        <TableCell sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#e5e5e5", fontWeight: "bold" }} align={"right"}>{budgetedTotal.toFixed(2)}</TableCell>
                        <TableCell sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#e5e5e5", fontWeight: "bold" }} align={"right"}>{burnedTotal.toFixed(2)}</TableCell>
                        <TableCell sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#e5e5e5", fontWeight: "bold" }} align={"right"}>{forecastedTotal.toFixed(2)}</TableCell>
                        <TableCell sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#e5e5e5", fontWeight: "bold", color: overUnderTotalColor }} align={"right"}>{overUnderTotal.toFixed(2)}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        forecastByRole && eacReport && Object.keys(forecastByRole).map((role: string, roleIndex: number) => (
                            <React.Fragment key = { role + ":" + roleIndex}>
                                <TableRow key={role} sx={{ flexGrow: 1, border: "1px solid grey" }}>
                                    <TableCell rowSpan={forecastByRole[role].length + 1} sx={{ flexGrow: 1, border: "1px solid grey" }}>{role}</TableCell>
                                </TableRow>
                                {
                                    forecastByRole[role].map((forecastName: string, index: number) => {
                                        let deltaColor = eacReport.eacReportLines[forecastName].currPrevDelta < 0 ? "red" : "black";
                                        let budgetColor = eacReport.eacReportLines[forecastName].budgetOverUnder < 0 ? "red" : "black";
                                        return (
                                            <TableRow key={role + "-" + eacReport.eacReportLines[forecastName].employeeName + "-" + index}>
                                                <TableCell sx={{ flexGrow: 1, border: "1px solid grey", fontWeight: "bold" }} title={eacReport.eacReportLines[forecastName].employeeID}>{eacReport.eacReportLines[forecastName].employeeName}</TableCell>
                                                <TableCell sx={{ flexGrow: 1, border: "1px solid grey", fontWeight: "bold" }} align={"right"}>{eacReport.eacReportLines[forecastName].prevEAC}</TableCell>
                                                <TableCell sx={{ flexGrow: 1, border: "1px solid grey", fontWeight: "bold" }} align={"right"}>{eacReport.eacReportLines[forecastName].currEAC}</TableCell>
                                                <TableCell sx={{ flexGrow: 1, border: "1px solid grey", fontWeight: "bold", color: deltaColor }} align={"right"}>{eacReport.eacReportLines[forecastName].currPrevDelta}%</TableCell>
                                                <TableCell sx={{ flexGrow: 1, border: "1px solid grey", fontWeight: "bold" }} align={"right"}>{eacReport.eacReportLines[forecastName].budgetedHours}</TableCell>
                                                <TableCell sx={{ flexGrow: 1, border: "1px solid grey", fontWeight: "bold" }} align={"right"}>{eacReport.eacReportLines[forecastName].burnedHours}</TableCell>
                                                <TableCell sx={{ flexGrow: 1, border: "1px solid grey", fontWeight: "bold" }} align={"right"}>{eacReport.eacReportLines[forecastName].forecastedHours}</TableCell>
                                                <TableCell sx={{ flexGrow: 1, border: "1px solid grey", color: budgetColor, fontWeight: "bold" }} align={"right"}>{eacReport.eacReportLines[forecastName].budgetOverUnder}</TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </React.Fragment>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

// TODO: ClassName not working properly/buggy
// TODO: error handling, audit history == null check/test
// TODO: Add alt={`Audit History ${forecastLine.resourceForecastLineID}`} and other alts
// TODO: color red any value < 0