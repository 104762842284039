import * as React from 'react';
import { useContext, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { AutocompleteChangeReason, AutocompleteRenderInputParams, autocompleteClasses, createFilterOptions } from '@mui/material/Autocomplete';
import useMediaQuery from '@mui/material/useMediaQuery';
import ListSubheader from '@mui/material/ListSubheader';
import Popper from '@mui/material/Popper';
import { useTheme, styled } from '@mui/material/styles';
import { VariableSizeList, ListChildComponentProps } from 'react-window'
import Typography from '@mui/material/Typography';
import { IExtendedEmployee } from '../models/IEmployee';
import { ImpersonatedUserContext  } from '../contexts/ImpersonatedUserContext';
import { useLocation } from 'react-router-dom';

const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
    };
 
    
  if (dataSet.hasOwnProperty('group')) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  return (
    <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
      {dataSet[1]}
      {/* {`#${dataSet[2] + 1} - ${dataSet[1]}`} */}
    </Typography>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
    const ref = React.useRef<VariableSizeList>(null);

    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData: React.ReactElement[] = [];
  (children as React.ReactElement[]).forEach(
    (item: React.ReactElement & { children?: React.ReactElement[] }) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    },
  );

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child: React.ReactElement) => {
    if (child.hasOwnProperty('group')) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index: number) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
        margin: 0,
        },
  },
});

interface IVirtualProps {
    options: IExtendedEmployee[];
}

export const VirtualizedSelector = (props: IVirtualProps) => {
    const { options } = props;
    const [inputValue, setInputValue] = useState('');
    const { impersonatedUser, setImpersonatedUser } = useContext(ImpersonatedUserContext);
    const location = useLocation();
    const [userListDisabled, setUserListDisabled] = useState<boolean>(true);

    React.useEffect(() => {
        if (location.pathname === '/') {
            setUserListDisabled(false);
        }
        else {
            setUserListDisabled(true);
        }
    }, [location])

    return (
      <>
          { 
            impersonatedUser &&
          <Autocomplete
              id="virtualized-user-selector-autocomplete"
              sx={{ width: 300, paddingRight: 2}}// removed padding 2%
              disableListWrap
              disableClearable
              PopperComponent={StyledPopper}
              ListboxComponent={ListboxComponent}
              options={options}
              groupBy={(option) => option.firstName[0].toUpperCase()}
              renderInput={(params: AutocompleteRenderInputParams) => <TextField {...params} label="Impersonating" variant="standard" />}
              renderOption={(props, option, state) =>
                  [props, `${option.firstName + ' ' + option.lastName} ${option.isTerminated ? '(Terminated)' : ''}`, state.index] as React.ReactNode
                }
                getOptionLabel={(option: IExtendedEmployee) => option.firstName + ' ' + option.lastName}
                renderGroup={(params) => params as any}
                filterOptions={createFilterOptions({
                  ignoreCase: true,
                  // limit: 10,
                  matchFrom: 'any',
                  trim: true,
                  stringify: (opt: IExtendedEmployee) => `${opt.firstName + ' ' + opt.lastName} ${opt.isTerminated ? '(Terminated)' : ''}`
                })}
                isOptionEqualToValue={(opt, val) => {
                  return opt.id === val.id
              }}
              value={impersonatedUser}
              onChange={(ev, val: IExtendedEmployee | null, reason: AutocompleteChangeReason, details) => setImpersonatedUser(val)}
              inputValue={inputValue}
              onInputChange={(ev, newInputValue, reason) => setInputValue(newInputValue)}
              disabled={userListDisabled}
          />
        }
      </>
    );
}

export default VirtualizedSelector;

