
interface IError {
  error?: Error;
}

export const ErrorPage = (props: IError) => {

  return (
      <div id="error-page">
          <h1>Oops!</h1>
          <p>
              Sorry, an unexpected error has occurred.
              <i>{props?.error?.message}. </i>
          </p>
          <p>
              <i> Please contact System Administrator.</i>
          </p>
      </div>
  );
}

export default ErrorPage;