import { Checkbox, Grid, Modal, TextField } from "@mui/material";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, createTheme, ThemeProvider, Button, Stack, Box } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import { useContext, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import useFetchWithMsal from "../hooks/useFetchWithMsal";
import { forecastServerLoginRequest } from "../util/AuthConfig";
import FlagIcon from '@mui/icons-material/Flag';

import Loading from "../components/Loading";
import ErrorPage from "./Error";
import IProjectForecastBudget, { IProjectLineBudget } from "../models/IProjectForecastBudget";
import { IUpdatedResourceBudget } from "../models/IUpdatedResourceBudget";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import tableStyles from '../styles/table-styles.module.css'
import React from "react";
//import { red } from "@material-ui/core/colors";

type MyRecord<T> = Record<string, string>;

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 4,
    px: 8,
    pb: 6,
};

export const Budget = () => {
    const { id } = useParams();
    const { execute } = useFetchWithMsal({ scopes: forecastServerLoginRequest.scopes });
    const { instance, inProgress } = useMsal();
    const queryClient = useQueryClient();
    const [updatedResourceBudget, setUpdatedResourceBudget] = useState<MyRecord<string>>({});
    const [projAuthsList, setProjAuthsList] = useState<string[]>([]);
    const [projAuthEMsList, setProjAuthEMsList] = useState<string[]>([]);
    const [updatedProjectBudget, setUpdatedProjectBudget] = useState<IUpdatedResourceBudget>();
    const [eacConfirmOpen, setEacConfirmOpen] = useState<boolean>(false);

    // Totals for the headers that need to be calculated everytime that user changes budget
    const [budgetedTotal, setBudgetedTotal] = useState<number>(0.00);
    const [varianceTotal, setVarianceTotal] = useState<number>(0.00);
    const [remainingOUTotal, setRemainingOUTotal] = useState<number>(0.00);
    const [remainingOUTotalColor, setRemainingOUTotalColor] = useState<string>("");

    // Calculate the header totals for budgeted, variance, eac, remaining and role remaining
    const calculateTotals = (data: IProjectForecastBudget) => {
        let budgetedSum = 0.00;
        let varianceSum = 0.00;
        let forecastedSum = 0.00;
        let remainingOUSum = 0.00;
        // Need to map through forecastbyRole and lines in order to calculate total
        Object.keys(data.forecastByRole).map((role: string) => {
            let sowPlusBudget: number = parseFloat(data.roleBudget[role])

            let individualBudgetRoleTotal: number = 0;
            for (let fl of data.forecastByRole[role]) {
                let indiv = parseFloat(data.forecastLineBudget[fl].budgetHours)
                individualBudgetRoleTotal += indiv
            }
            // Calculate Variance for role
            let variance: number = sowPlusBudget - individualBudgetRoleTotal
            varianceSum += variance

            data.forecastByRole[role].map((forecastLineID: string) => {
                budgetedSum += parseFloat(data.forecastLineBudget[forecastLineID].budgetHours);
                forecastedSum += parseFloat(data.forecastLineBudget[forecastLineID].forecastHours);
                let eac = parseFloat(data.forecastLineBudget[forecastLineID].burnedHours) + parseFloat(data.forecastLineBudget[forecastLineID].forecastHours)
                let remaining = parseFloat(data.forecastLineBudget[forecastLineID].budgetHours) - eac
                remainingOUSum += remaining
            })
        })
        setBudgetedTotal(budgetedSum);
        setVarianceTotal(varianceSum);
        let remainingOUColor = remainingOUSum < 0 ? "red" : "black"
        setRemainingOUTotal(remainingOUSum);
        setRemainingOUTotalColor(remainingOUColor);
    }

    // Method with initial request to get Budget report with passed in projectId 
    const useBudgetQuery = () => {
        return useQuery<IProjectForecastBudget | null>({
            queryKey: ['budget', { instance, inProgress, id }],
            queryFn: async ({ queryKey }) => {
                const [_key, { instance: localInstance, inProgress, id }] = queryKey as any
                const projectForecastBudget: IProjectForecastBudget | null = await execute<IProjectForecastBudget>('POST', '../api/Home/Budget', id );
                if (!projectForecastBudget) {
                    throw new Error("Could not retrieve Budget Report.")
                }
                let temp = { ...projectForecastBudget }
                // created new array of strings to be returned because not doing so will alter the original data
                // need to create array of projAuths, projAuthEms: later get sent to server to save
                const projAuthEMsTemp = removeEMsFromBudgetAccess(temp);
                const projAuthListTemp = addProjAuthsFromBudgetAccess(temp);
                setProjAuthEMsList(projAuthEMsTemp);
                setProjAuthsList(projAuthListTemp);
                calculateTotals(projectForecastBudget);

                return projectForecastBudget;
            },
            refetchOnWindowFocus: false, // keeps the query from refetching if you switch tabs
        })
    }

    const { data, isLoading, isPending, isFetching, error } = useBudgetQuery()

    // useMuation to call api to save the data that has been changed
    const useMutateSaveBudget = useMutation({
        mutationFn: async () => await execute('POST', '../api/Home/SaveProjectBudget', updatedProjectBudget ),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['budget'] });
            toast.success("Saved Project Budget!");
        }
    })

    // Mutation w/ no mutate function or api. strictly used to mutate the UI individual budget data (onMutate)
    const useMutateIndividualBudget = useMutation({
        onMutate: async (params: { forecastLineID: string, individualBudgetHours: number }) => {
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries({queryKey: ['budget', { instance, inProgress, id }]})

            // Snapshot the previous value
            const previousData: IProjectForecastBudget | undefined = queryClient.getQueryData(['budget']);// does not return data as documentation explains

            if (data) {
                data.forecastLineBudget[params.forecastLineID].budgetHours = params.individualBudgetHours.toFixed(2);
                // Documentation declares that this should be something to do, but not actually working
                //queryClient.setQueryData(['budget', { instance, inProgress, id }], (prevData) => {
                //    return { newData: { ...data } }
                //});
                calculateTotals(data);
            }
        }
    })

    // Mutation w/ no mutate function or api. strictly used to mutate the UI individual budgets data (onMutate)
    const useMutateAllIndividualBudgets = useMutation({
        onMutate: async () => {
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries({ queryKey: ['budget', { instance, inProgress, id }] })

            // Snapshot the previous value
            const previousData: IProjectForecastBudget | undefined = queryClient.getQueryData(['budget']);// does not return data

            if (data) {
                // Need to map through forecastbyRole and lines in order to set eac value to rsource budget 
                let tempUpdatedResourceBudget: MyRecord<string> = {};
                Object.keys(data.forecastByRole).map((role: string) => {
                    data.forecastByRole[role].map((forecastLineID: string) => {
                        let eac = parseFloat(data.forecastLineBudget[forecastLineID].burnedHours) + parseFloat(data.forecastLineBudget[forecastLineID].forecastHours);
                        data.forecastLineBudget[forecastLineID].budgetHours = eac.toFixed(2);
                        tempUpdatedResourceBudget[forecastLineID] = eac.toFixed(2); // Adds eac value to record that will be used to 
                    })
                })
                // Add or replace resource budget state to later be saved
                setUpdatedResourceBudget(tempUpdatedResourceBudget);
                calculateTotals(data);
            }
        }
    })

    // Mutates the budget access array that declares which resource is disabled. Adds the resource when checkbox clicked initially and removes if clicked again
    const useMutateBudgetAccess = useMutation({
        onMutate: async (params: { forecastLineID: string, employeeID: string }) => {
            // Cancel any outgoing refetches
            // (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries({ queryKey: ['budget', { instance, inProgress, id }] })

            // Snapshot the previous value
            const previousData: IProjectForecastBudget | undefined = queryClient.getQueryData(['budget']);

            if (data) {
                data.budgetAccess[params.employeeID] ? delete data.budgetAccess[params.employeeID] : data.budgetAccess[params.employeeID] = "A";
            }
        }
    })

    // Show components based on Query Results!
    if (isPending || isFetching) {
        return <Loading paddingValue={4} />
    }
    if (error) {
        return <ErrorPage error={error} />
    }

    function removeEMsFromBudgetAccess(pfb: IProjectForecastBudget): string[]{
        let budgetAccessRecords: Record<string, string> = pfb.budgetAccess;
        let projAuthEMsArray: string[] = [];
        for (const key in budgetAccessRecords) {
            if (budgetAccessRecords[key] === "E" ) {
                projAuthEMsArray.push(key); // add the element with the specified value to array
            }
        }
        return projAuthEMsArray;
    }

    function addProjAuthsFromBudgetAccess(pfb: IProjectForecastBudget): string[] {
        let budgetAccessRecords: Record<string, string> = pfb.budgetAccess;
        let projAuthsArray: string[] = [];
        for (const key in budgetAccessRecords) {
            if (budgetAccessRecords[key] === "A") {
                projAuthsArray.push(key); // add the element with the specified value to array
            }
        }
        return projAuthsArray;
    }

    // Update individual budget by role UI state 
    const updateIndividualBudgetByRole = (forecastLineID: string, individualBudgetHours: number) => {

        let individualBudgetHoursString: string = individualBudgetHours.toFixed(2);
        const budgetParams = {
            forecastLineID: forecastLineID,
            individualBudgetHours: individualBudgetHours
        }
        // Add or replace resource budget state to later be saved 
        const updatedBudgets = addOrReplaceResourceBudget(updatedResourceBudget, forecastLineID, individualBudgetHoursString)
        setUpdatedResourceBudget(updatedBudgets);
        // Call to mutate the UI 
        useMutateIndividualBudget.mutate(budgetParams);
    }

    // add or replace the resource budget in the Record<lineId, hours> to later be saved when save clicked (allows the UI to update)
    function addOrReplaceResourceBudget<T>(
        existingRecord: MyRecord<T>,
        key: string,
        value: string
    ): MyRecord<T> {
        const updatedRecord = { ...existingRecord };
        updatedRecord[key] = value;
        return updatedRecord;
    }

    const checkedBudgetClick = (forecastLineID: string) => {
        const employeeID = data?.forecastLineBudget[forecastLineID].employeeID ?? "";
        console.info('Checked budget for: ' + forecastLineID + ", EmployeeId: " + employeeID);
        // if the line is in the que to update, replace it, otherwise add it
        const tempAuthList = addOrRemoveProjAuths(projAuthsList, employeeID);
        setProjAuthsList(tempAuthList);

        const budgetParams = {
            forecastLineID: forecastLineID,
            employeeID: employeeID ?? ""
        }

        useMutateBudgetAccess.mutate(budgetParams);
    }

    // Add or remove the projAuths in the string[] which is handled by the checkbox and value is employeeId to later be saved when save clicked (allows the UI to update)
    function addOrRemoveProjAuths(array: string[], item: string):string[] {
        const exists = array.includes(item)

        if (exists) {
            return array.filter((c) => { return c !== item })
        } else {
            const result = array
            result.push(item)
            return result
        }
    }

    const saveBudgetClick = async () => {
        // Params to be sent over to the api call to save budget 
        const params: IUpdatedResourceBudget = {
            projectID: id ?? "",
            projAuthEMs: projAuthEMsList,
            projAuths: projAuthsList,
            resourceBudget: updatedResourceBudget
        }
        setUpdatedProjectBudget(params);
        useMutateSaveBudget.mutate();
    }

    // assign the EAC to each budget when button clicked
    const assignEACClick = async () => {
        setEacConfirmOpen(false) 
        await useMutateAllIndividualBudgets.mutate();
    }

    // prevent the wheel from changing the input
    const numberInputOnWheelPreventChange = (event: any) => {
        // Prevent the input value change
        event.target.blur()

        // Prevent the page/container scrolling
        event.stopPropagation()

        // Refocus immediately, on the next tick (after the current function is done)
        setTimeout(() => {
            event.target.focus()
        }, 0)
    }

    // Main Budget Table
    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover/>
            {!isLoading &&
                <>
                    <Box>
                        <Typography variant='h4' sx={{ paddingTop: 2 }}>
                            {`Budget Report: ${data?.projectName}`}
                        </Typography>
                    </Box>
                <div className={tableStyles.buttonSpacing}>
                    <Button variant="contained" sx={{ margin: '10px' }} className={tableStyles.buttonColor} onClick={() => { saveBudgetClick()} }>Save</Button>
                    <Button variant="contained" sx={{ margin: '10px' }} className={tableStyles.buttonColor} onClick={() => { setEacConfirmOpen(true) }}>Assign EAC to All Budget Rows</Button>
                    <Modal
                        open={eacConfirmOpen}
                        aria-labelledby="parent-modal-title"
                        aria-describedby="parent-modal-description"
                        onClose={(_, reason) => {
                            if (reason === "backdropClick") {
                                setEacConfirmOpen(false);
                            }
                        }}
                    >
                        <Box sx={{ ...style, width: 400 }}>
                            <h2 id="confirmation-eac-modal">Confirm Assign EAC to All Budget</h2>
                            <p id="eac-modal-description">
                                This action will overwrite any values already added to the Individual Budget column. Do you wish to continue?
                            </p>
                            <Button variant="contained" sx={{ margin: '10px' }} className={tableStyles.buttonColor} onClick={() => { assignEACClick() }}>Confirm</Button>
                            <Button variant="contained" sx={{ margin: '10px' }} className={tableStyles.buttonColor} onClick={() => { setEacConfirmOpen(false) }}>Cancel</Button>
                        </Box>
                    </Modal>
                    </div>
                    <TableContainer sx={{ paddingLeft: 0, paddingRight: 0, borderRight: "1px solid grey", marginTop: 2, boxShadow: 5 }}>
                        <Table id="forecasttable" sx={{ minWidth: 500, borderCollapse: "collapse", paddingLeft: 0, paddingRight: 0 }}>
                            <TableHead>
                            {/*Header Row*/}
                            <TableRow key={"trh-1"} sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#e5e5e5", color: "#fff" }}>
                                <TableCell colSpan={3} sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#e5e5e5" }}></TableCell>
                                <TableCell colSpan={3} sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#e5e5e5", fontWeight: "bold" }} align={"center"}>SOW+CO Budget By Role</TableCell>
                                <TableCell colSpan={4} sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#e5e5e5", fontWeight: "bold" }} align={"center"}>Individual</TableCell>
                                <TableCell colSpan={1} sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#e5e5e5", fontWeight: "bold" }} align={"center"}>Role</TableCell>
                            </TableRow>
                            {/*Header Row*/}
                            <TableRow key={"trh-2"}>
                                <TableCell sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#6A6867", color: "#fff", fontWeight: "bold" }} align={"center"}>Role</TableCell>
                                <TableCell sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#6A6867", color: "#fff", fontWeight: "bold" }} align={"center"}></TableCell>
                                <TableCell sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#6A6867", color: "#fff", fontWeight: "bold" }} align={"center"}>Resource</TableCell>
                                <TableCell sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#6A6867", color: "#fff", fontWeight: "bold" }} align={"center"}>SOW+CO Budget</TableCell>
                                <TableCell sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#6A6867", color: "#fff", fontWeight: "bold" }} align={"center"}>Individual Budget</TableCell>
                                <TableCell sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#6A6867", color: "#fff", fontWeight: "bold" }} align={"center"}>SOW+CO Variance</TableCell>
                                <TableCell sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#6A6867", color: "#fff", fontWeight: "bold" }} align={"center"}>Burned</TableCell>
                                <TableCell sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#6A6867", color: "#fff", fontWeight: "bold" }} align={"center"}>Forecasted</TableCell>
                                <TableCell sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#6A6867", color: "#fff", fontWeight: "bold" }} align={"center"}>EAC</TableCell>
                                <TableCell sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#6A6867", color: "#fff", fontWeight: "bold" }} align={"center"}>Remaining <br />(Over/Under)</TableCell>
                                <TableCell sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#6A6867", color: "#fff", fontWeight: "bold" }} align={"center"}>Role Remaining <br />(Over/Under)</TableCell>
                            </TableRow>
                            {/*Totals Row*/}
                            <TableRow key={"trh-3"}>
                                <TableCell colSpan={3} sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#e5e5e5" }}>Totals</TableCell>
                                <TableCell sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#e5e5e5", fontWeight: "bold" }} align={"center"}>{data?.totalRoleBudgetHours}</TableCell>
                                <TableCell sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#e5e5e5", fontWeight: "bold" }} align={"center"}>{budgetedTotal.toFixed(2)}</TableCell>
                                <TableCell sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#e5e5e5", fontWeight: "bold" }} align={"center"}>{varianceTotal.toFixed(2)}</TableCell>
                                <TableCell sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#e5e5e5", fontWeight: "bold" }} align={"center"}>{parseFloat(data?.totalBurnedHours ?? "0")}</TableCell>
                                <TableCell sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#e5e5e5", fontWeight: "bold" }} align={"center"}>{parseFloat(data?.totalForecastedHours ?? "0")}</TableCell>
                                <TableCell sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#e5e5e5", fontWeight: "bold" }} align={"center"}>{parseFloat(data?.totalEACHours ?? "0")}</TableCell>
                                <TableCell sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#e5e5e5", fontWeight: "bold", color: remainingOUTotalColor }} align={"center"}>{remainingOUTotal.toFixed(2)}</TableCell>
                                <TableCell sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#e5e5e5", fontWeight: "bold", color: remainingOUTotalColor }} align={"center"}>{remainingOUTotal.toFixed(2)}</TableCell>
                            </TableRow>
                            </TableHead>
                        <TableBody>
                            {data && Object.keys(data.forecastByRole).map((role: string, roleIndex: number) => {
                                // role example: "WD Absence Management Associate"
                                // forecastLineId example: "RESOURCE_FORECAST_LINE-3-549113"
                                // the only columns that need to be re-calculated when "Individual Budget" is changed is:
                                // Individual Budget HEADER TOTAL
                                // SOW + CO Variance HEADER TOTAL | SOW + CO Variance
                                // Remaining (Over/Under) HEADER TOTAL |  	Remaining (Over/Under)
                                // Role Remaining (Over/Under) HEADER TOTAL | Role Remaining (Over/Under)
                                const forecastLinesByRole: string[] = data.forecastByRole[role]
                                const roleCountPlusOne: number = forecastLinesByRole.length + 1

                                // the columns that need to be summed are "SOW+CO Budget", "SOW+CO Variance", and "Role Remaining (Over/Under)"
                                let sowPlusBudget: number = parseFloat(data.roleBudget[role])
                                let individualBudgetRoleTotal: number = 0;
                                let roleRemainingOU: number = 0;
                                for (let fl of forecastLinesByRole) {
                                    let plb: IProjectLineBudget = data.forecastLineBudget[fl]
                                    let indiv = parseFloat(plb.budgetHours)
                                    individualBudgetRoleTotal += indiv

                                    let burned1: number = parseFloat(plb.burnedHours)
                                    let forecast1: number = parseFloat(plb.forecastHours)
                                    let eac1 = burned1 + forecast1
                                    let remainingOU = indiv - eac1
                                    roleRemainingOU += remainingOU
                                }
                                // Calculate Variance for role and set color to red if negative
                                let variance: number = sowPlusBudget - individualBudgetRoleTotal
                                let varianceColor = variance < 0 ? "red" : "black"
                                let roleRemainingColor = roleRemainingOU < 0 ? "red" : "black"

                                return (
                                    <React.Fragment key={role + ":" + roleIndex}>
                                        <TableRow sx={{ flexGrow: 1, border: "1px solid grey" }}>
                                            <TableCell rowSpan={roleCountPlusOne} sx={{ flexGrow: 1, border: "1px solid grey", fontWeight: "bold" }}>{role}</TableCell>
                                        </TableRow>
                                        {
                                            forecastLinesByRole.map((forecastLineID: string, lineIndex: number) => {
                                                let projectLineBudget: IProjectLineBudget = data.forecastLineBudget[forecastLineID]
                                                let employeeName: string = projectLineBudget.employeeName
                                                let individualBudget: number = parseFloat(projectLineBudget.budgetHours)
                                                let burned: number = parseFloat(projectLineBudget.burnedHours)
                                                let forecast: number = parseFloat(projectLineBudget.forecastHours)
                                                let eac = burned + forecast
                                                let remainingOverUnder: number = individualBudget - eac
                                                let remainingColor = remainingOverUnder < 0 ? "red" : "black"
                                                let authType = ""
                                                let isChecked = false
                                                let isDisabled = false
                                                let isInputBudgetDisabled = false
                                                // Check if the resource employeeID for role is in budget access, if the line employeeID is in the budgetAccess array make checked. set authtype to value of matching key
                                                // If value of matching key is E | D or "" make disabled to true
                                                if (data.budgetAccess[data.forecastLineBudget[forecastLineID].employeeID]) {
                                                    isChecked = true;
                                                    authType = data.budgetAccess[data.forecastLineBudget[forecastLineID].employeeID];
                                                }

                                                if (authType.includes("E") || authType.includes("D") || data.forecastLineBudget[forecastLineID].employeeName === "") {
                                                    isDisabled = true;
                                                }
                                                // Make budget input disabled if no resource name for line
                                                if (data.forecastLineBudget[forecastLineID].employeeName === "") { isInputBudgetDisabled = true; }
                                                return (
                                                    <TableRow key={role + '-' + forecastLineID + '-' + employeeName + '-' + lineIndex}>
                                                        <TableCell aria-label="chk-box" sx={{ border: "1px solid grey", fontWeight: "bold", lineHeight: 1 }}>
                                                            <Checkbox checked={isChecked} onChange={() => checkedBudgetClick(forecastLineID)} disabled={isDisabled} value={data.forecastLineBudget[forecastLineID].employeeID } />
                                                        </TableCell>
                                                        {
                                                            authType.includes("D") && data.forecastLineBudget[forecastLineID].resourceForecastLine.startsWith("MISSING") ?
                                                                <TableCell aria-label="bgt-resource" sx={{ flexGrow: 1, border: "1px solid grey", fontWeight: "bold" }}>{employeeName} <FlagIcon /></TableCell>
                                                                :
                                                                <TableCell aria-label="bgt-resource" sx={{ flexGrow: 1, border: "1px solid grey", fontWeight: "bold" }}>{employeeName} </TableCell>
                                                        }
                                                        {
                                                            lineIndex === 0 ?
                                                                <TableCell aria-label="bgt-sow-plus-budget" rowSpan={roleCountPlusOne} sx={{ flexGrow: 1, border: "1px solid grey", fontWeight: "bold" }} align={"center"}>{sowPlusBudget}</TableCell> :
                                                                <></>
                                                        }
                                                        <TableCell aria-label="bgt-indiv-budget" sx={{ flexGrow: 1, border: "1px solid grey" }}>
                                                            <TextField id={forecastLineID} name={forecastLineID} type="number" disabled={isInputBudgetDisabled} onWheel={numberInputOnWheelPreventChange} value={individualBudget} onChange={(event) => updateIndividualBudgetByRole(forecastLineID, Number(event.target.value))} />
                                                        </TableCell>
                                                        {
                                                            lineIndex === 0 ?
                                                                <TableCell aria-label="bgt-sow-plus-var" rowSpan={roleCountPlusOne} sx={{ flexGrow: 1, border: "1px solid grey", fontWeight: "bold", color: varianceColor }} align={"center"}>{variance.toFixed(2)}</TableCell> :
                                                                <></>
                                                        }
                                                        <TableCell aria-label="bgt-burned" sx={{ flexGrow: 1, border: "1px solid grey", fontWeight: "bold" }} align={"center"}>{burned}</TableCell>
                                                        <TableCell aria-label="bgt-forecasted" sx={{ flexGrow: 1, border: "1px solid grey", fontWeight: "bold" }} align={"center"}>{forecast.toFixed(2)}</TableCell>
                                                        <TableCell aria-label="bgt-eac" sx={{ flexGrow: 1, border: "1px solid grey", fontWeight: "bold" }} align={"center"}>{eac.toFixed(2)}</TableCell>
                                                        <TableCell aria-label="bgt-remain" sx={{ flexGrow: 1, border: "1px solid grey", fontWeight: "bold", color: remainingColor }} align={"center"}>{remainingOverUnder.toFixed(2)}</TableCell>
                                                        {
                                                            lineIndex === 0 ?
                                                                <TableCell aria-label="bgt-role-remain" rowSpan={roleCountPlusOne} sx={{ flexGrow: 1, border: "1px solid grey", fontWeight: "bold", color: roleRemainingColor }} align={"center"}>{roleRemainingOU.toFixed(2)}</TableCell> :
                                                                <></>
                                                        }
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </React.Fragment>
                                )
                            })}
                        </TableBody>
                        </Table>
                    </TableContainer>
                    <div className="buttonSpacing">
                        <Button variant="contained" sx={{ margin: '10px' }} className={tableStyles.buttonColor}onClick={() => { saveBudgetClick()}}>Save</Button>
                        <Button variant="contained" sx={{ margin: '10px' }} className={tableStyles.buttonColor} onClick={() => { setEacConfirmOpen(true) }}>Assign EAC to All Budget Rows</Button>
                    </div>
                </>
            }
        </>
    )
}

export default Budget;