import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, createTheme, ThemeProvider, Button, Stack } from "@mui/material";
import { useContext, useState, useRef, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { useMsal } from "@azure/msal-react";
import { forecastServerLoginRequest } from '../util/AuthConfig';
import { DateTime } from "luxon";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { ImpersonatedUserContext } from "../contexts/ImpersonatedUserContext";
import { IResourceForecastModel } from "../models/IResourceForecastModel";
import IResourceForecastLineData from "../models/IResourceLineData";
import styles from '../styles/project-styles.module.css';
import ProcessImage from '../images/process.png'
import CircleHistory64 from '../images/circleHistory64.png'
import { IUpdatedResourceForecast } from "../models/IUpdatedResourceForecast";
import Loading from "../components/Loading";
import ErrorPage from "./Error";
import tableStyles from '../styles/table-styles.module.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const theme = createTheme({
    typography: {
        subtitle1: {
            fontSize: 14,
        },
    },
});

export const Forecast = () => {
    const { zeroFlag, id} = useParams();
    const { execute } = useFetchWithMsal({ scopes: forecastServerLoginRequest.scopes });
    const { impersonatedUser } = useContext(ImpersonatedUserContext);
    const { instance, inProgress } = useMsal();
    const [resourceForecast, setResourceForecast] = useState<IResourceForecastModel>();
    const currMonthYearKey = useRef<string>("");
    const queryClient = useQueryClient();
    const [updatedForecasts, setUpdatedForecasts] = useState<IUpdatedResourceForecast[]>([]);

    // TODO: Use this correctly to use Data 
    // Get the Resource Forecast for the impersonted user when mounted
    const Init = () => {
        let includeZeroHours = zeroFlag === 'true' ? true : false;
        let projectId = id?.includes("PROJ") ? id : null;
        return useQuery<IResourceForecastModel>({
            queryKey: ['forecast', { instance, inProgress }],
            queryFn: async ({ queryKey }) => {
                const [_key, { instance: localInstance }] = queryKey as any
                const resourceForecastImpl: IResourceForecastModel | null = await execute<IResourceForecastModel>('POST', '/api/Home/GetForecast', { projectId, includeZeroHours} );
                if (!resourceForecastImpl) {
                    throw new Error("Could not retrieve forecasts.")
                }
                setResourceForecast(resourceForecastImpl)
                return resourceForecastImpl;
            },
            refetchOnWindowFocus: false, // keeps the query from refetching if you switch tabs
         })
    }

    const { data, isPending, isFetching, error, refetch } = Init();

    const saveForecast = useMutation({
        mutationFn: async () => await execute('POST', '/api/Home/SaveResourceForecast36', updatedForecasts),
        onSuccess: () => {
            setUpdatedForecasts([]); // clears the updatedForecasts so that a new list could be created to later be saved
            queryClient.invalidateQueries({ queryKey: ['forecast'] });
        }
    })

    // Method for forecast project to be refreshed. Runs after the api has already been called. Used to update the UI to show refresh status
    const queueForecastLine = useMutation({
        onMutate: async (forecastLine: IResourceForecastLineData ) => {
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries({ queryKey: ['budget', { instance, inProgress, id }] })

            // Snapshot the previous value
            const previousData: IResourceForecastModel | undefined = queryClient.getQueryData(['forecast']);// does not return data as documentation explains

            // set forecastLine.isProjectUpdating to true for the forecastline queued so the UI displays that info.
            resourceForecast?.resourceForecastLines?.map((line: IResourceForecastLineData) => {
                if (line.projectID === forecastLine.projectID) {
                    line.isProjectUpdating = true;
                    return line;
                }
                return line;
            });
        }
    })

    if (isPending || isFetching) {
        return <Loading paddingValue={2}/>
    }

    if (error) {
        return <ErrorPage error={error} />
    }

    // check whether the colum date is betwen the adjusted project start date and project end dates
    const dateInRange = (dateColumn: string, forecastLine: IResourceForecastLineData): boolean => {
        const date = DateTime.fromFormat(dateColumn, "yyyyMMdd");
        const adjustedProjectStartDate = DateTime.fromISO(forecastLine.adjustedProjectStartDate);
        const projectEndDate = DateTime.fromISO(forecastLine.projectEndDate.toString());
        if (date.isValid && adjustedProjectStartDate.isValid && projectEndDate.isValid ) {
            return adjustedProjectStartDate <= date && projectEndDate >= date;
        }
        return false;
    }

    const calculateForecastedHours = (currentLine: IResourceForecastLineData): number => {
        let totalHours = 0
        resourceForecast?.dateColumns.forEach((date) => {
            if (dateInRange(date, currentLine)) {
                const dataMap: number = currentLine.resourceForecastLineAllocationDataMap[date] ?? 0;
                totalHours += dataMap
            }
        })

        return totalHours;
    }

    const calculateRemainingHours = (currentLine: IResourceForecastLineData): number => {

        // Available - Forecasted
        const availableHours: number = currentLine.budgetedHours - currentLine.hoursBurned
        const forecastedHours: number = calculateForecastedHours(currentLine)

        return availableHours - forecastedHours
    }

    // Update hours for a resourceForecastLineAllocationDataMap if available, otherwise adds, update CalculatedHours row
    const updateForecastedHours = (forecastLine: IResourceForecastLineData, date: string, hours: number) => {
        
        let newRF: IResourceForecastLineData = 
        { 
            ...forecastLine, 
            resourceForecastLineAllocationDataMap: { ...forecastLine.resourceForecastLineAllocationDataMap, [date]: hours }
        }

        // update the array with the newly edited hours
        // const except: IResourceForecastLineData[] = resourceForecast?.resourceForecastLines.filter((rf: IResourceForecastLineData) => rf.projectID !== forecastLine.projectID) ?? []
        const newArray: IResourceForecastLineData[] = resourceForecast?.resourceForecastLines.map((rf: IResourceForecastLineData) => {
            if (rf.resourceForecastLineID === forecastLine.resourceForecastLineID) {
                return newRF;
            } 
            return rf;
        }) ?? []

        // sum and update total hours including holiday and pto hours
        let sum = 0;
        newArray.forEach(n => sum += (n.resourceForecastLineAllocationDataMap[date] ?? 0))
        const holidayTimeOff = resourceForecast?.holidayLineData?.resourceForecastLineAllocationDataMap[date] ?? 0
        const paidTimeOff = resourceForecast?.timeOffLineData?.resourceForecastLineAllocationDataMap[date] ?? 0
        sum += (holidayTimeOff + paidTimeOff) // must include time off and pto
        const updatedTotal = { ...resourceForecast?.totalHoursByDate, [date]: sum }
        
        // Have to use prevState due to nested objects
        // React will only compare primitive values, so we have to utlize the previous state to update the new state
        setResourceForecast(prevState => (
            {
                beginDateAllProjects: prevState?.beginDateAllProjects ?? '',
                canSeeAllHeadersPhase2: prevState?.canSeeAllHeadersPhase2 ?? '',
                dateColumns: prevState?.dateColumns ?? [],
                dateColumnsHeaders: prevState?.dateColumnsHeaders ?? [],
                dateFormatUI: prevState?.dateFormatUI ?? '',
                dateColumnsMonthSpan: prevState?.dateColumnsMonthSpan,
                employeeID: prevState?.employeeID ?? '',
                employeeName: prevState?.employeeName ?? '',
                hasBillableProjects: prevState?.hasBillableProjects ?? false,
                hasNonBillableProjects: prevState?.hasNonBillableProjects ?? false,
                lastDateAllProjects: prevState?.lastDateAllProjects ?? '',
                today: prevState?.today ?? '',
                totalHoursByDate: updatedTotal,
                weeklyDateOffset: prevState?.weeklyDateOffset ?? 0,
                holidayLineData: prevState?.holidayLineData ?? null,
                timeOffLineData: prevState?.timeOffLineData ?? null,
                resourceForecastLines: [...newArray]
            }
        ))

        let resourceForecastToUpdate: IUpdatedResourceForecast = {
            projectID: forecastLine.projectID,
            resourceForecastLineID: forecastLine.resourceForecastLineID,
            forecastDate: date,
            forecastedHours: hours
        };

        // if the forecasts is in the list to update, replace it, otherwise add it
        const updForecasts = addOrReplaceByKey(updatedForecasts, resourceForecastToUpdate, 'resourceForecastLineID', 'forecastDate');
        
        setUpdatedForecasts(updForecasts);
    }

    function addOrReplaceByKey<T extends { [key: string]: any }>(
        arrayOfObjects: T[],
        newObject: T,
        idKey: keyof T,
        dateKey: keyof T
    ): T[] {
        const index = arrayOfObjects.findIndex((object) => object[idKey] === newObject[idKey] && object[dateKey] === newObject[dateKey]);

        if (index !== -1) {
            // Replace existing object
            arrayOfObjects[index] = newObject;
        } else {
            // Add new object
            arrayOfObjects.push(newObject);
        }

        return arrayOfObjects;
    }

    const queueForecast = async (forecastLine: IResourceForecastLineData) => {

        try {
            const projectId = forecastLine.projectID;

            const sec = await execute('POST', '/api/Home/AddUpdatedProjectToQueue', projectId);
            if (!sec) {
                toast.error("Could not queue forecast.");
            }
            if (sec === 200) {
                toast.success("Project in Queue!");
                queueForecastLine.mutate(forecastLine);
            }
        }
        catch (e: any) {
            toast.error(e);
            console.log(e);
        }
    } 

    const refreshClick = async() => {
        refetch();
    }

    // prevent the wheel from changing the input
    const numberInputOnWheelPreventChange = (event: any) => {
        // Prevent the input value change
        event.target.blur()

        // Prevent the page/container scrolling
        event.stopPropagation()

        // Refocus immediately, on the next tick (after the current function is done)
        setTimeout(() => {
            event.target.focus()
        }, 0)
    }

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
            <ThemeProvider theme={theme}>
                {/*sx={{ paddingLeft: 0, paddingRight: 0, borderRight: "1px solid grey", marginTop: 2, boxShadow: 5 }}*/}
            <TableContainer id="forecast-container" sx={{ paddingLeft: 0, paddingRight: 0, borderRight: "1px solid grey", marginTop: 2, boxShadow: 5  }} > 
                    <Table id="forecast-table" sx={{ minWidth: 500, borderCollapse: "collapse", paddingLeft: 0, paddingRight: 0 }} size={'small'}>
                    {
                        resourceForecast &&
                        <TableHead>
                        {/* totalsrowtr*/}
                        <TableRow id="totalsrowtr">
                                        <TableCell colSpan={3} sx={{ borderBottom: "0px" }}></TableCell>
                            <TableCell colSpan={3} sx={{ paddingLeft: 5, border: "1px solid grey", backgroundColor: "#6A6867", color: "#fff" }}></TableCell>
                            <TableCell sx={{ paddingLeft: 5, border: "1px solid grey", backgroundColor: "#6A6867", color: "#fff", lineHeight: "2px" }}>Totals</TableCell>
                            {
                                resourceForecast.dateColumns.map((dateString: string, dateIndex: number) => {
                                    if (resourceForecast.totalHoursByDate[dateString]) {
                                        if (resourceForecast.totalHoursByDate[dateString] > 50) {
                                            return (<TableCell key={dateIndex} style={{ backgroundColor:"#B81F2D" }} sx={{ flexGrow: 1, border: "1px solid grey", padding: "4px" }}>{resourceForecast.totalHoursByDate[dateString].toFixed(2)}</TableCell>)
                                        }
                                        else if (resourceForecast.totalHoursByDate[dateString] > 45) {
                                            return (<TableCell key={dateIndex} style={{ backgroundColor: "#E9C71D" }} sx={{ flexGrow: 1, border: "1px solid grey", padding: "4px" }}>{resourceForecast.totalHoursByDate[dateString].toFixed(2)}</TableCell>)
                                        }
                                        return (<TableCell key={dateIndex} className={styles.rfValueTotals} sx={{ flexGrow: 1, border: "1px solid grey", padding: "4px" }}>{resourceForecast.totalHoursByDate[dateString].toFixed(2)}</TableCell>)
                                    }
                                    return (<TableCell key={dateIndex} sx={{ flexGrow: 1, border: "1px solid grey", borderCollapse: "collapse", padding: "4px" }}></TableCell>)
                                })
                            }
                        </TableRow>
                        {/* holidaysrowtr*/}
                        <TableRow id="holidaysrowtr">
                            <TableCell colSpan={3} sx={{ borderBottom: "0px", alignContent: "center", fontSize: 36 }} align={"center"} title={impersonatedUser?.id}>{impersonatedUser?.fullUserName ?? ""}</TableCell> 
                            <TableCell colSpan={3} sx={{ paddingLeft: 5, border: "1px solid grey", backgroundColor: "#6A6867", color: "#fff" }}></TableCell>
                            <TableCell sx={{ paddingLeft: 5, border: "1px solid grey", backgroundColor: "#6A6867", color: "#fff", lineHeight: "5px"}}>Holiday</TableCell>
                            {
                                resourceForecast.dateColumns.map((dateString: string, dateIndex: number) => {
                                    if (resourceForecast.holidayLineData != null && resourceForecast.holidayLineData.resourceForecastLineAllocationDataMap != null && resourceForecast.holidayLineData.resourceForecastLineAllocationDataMap[dateString] && resourceForecast.holidayLineData.resourceForecastLineAllocationDataMap[dateString] > 0) {
                                        return (<TableCell key={dateIndex} sx={{ flexGrow: 1, border: "1px solid grey", lineHeight: "normal", padding: "4px" }} className={styles.rfValueTotals}>{resourceForecast.holidayLineData.resourceForecastLineAllocationDataMap[dateString].toFixed(2)}</TableCell>)
                                    }
                                    return <TableCell key={dateIndex} sx={{ flexGrow: 1, border: "1px solid grey", lineHeight: "normal", padding: "4px" }} className={styles.rfValueTotals} align={"center"}></TableCell>
                                })
                            }
                        </TableRow>
                        {/* ptorowtr*/}
                        <TableRow id="ptorowtr">
                            <TableCell colSpan={3} sx={{ borderBottom: "0px", alignContent: "center", fontSize: 15 }} align={"center"} title={zeroFlag}>{zeroFlag === 'true' ? "In" : "Ex"}cluding zero hour forecasts</TableCell>
                            <TableCell colSpan={3} sx={{ paddingLeft: 5, border: "1px solid grey", backgroundColor: "#6A6867", color: "#fff" }}></TableCell>
                            <TableCell sx={{ paddingLeft: 5, border: "1px solid grey", textWrap: "nowrap", backgroundColor: "#6A6867", color: "#fff", lineHeight: "5px" }}>Paid Time Off</TableCell>
                            {
                                resourceForecast.dateColumns.map((dateString: string, dateIndex: number) => {
                                    if (resourceForecast.timeOffLineData != null && resourceForecast.timeOffLineData.resourceForecastLineAllocationDataMap != null && resourceForecast.timeOffLineData.resourceForecastLineAllocationDataMap[dateString] && resourceForecast.timeOffLineData.resourceForecastLineAllocationDataMap[dateString] > 0) {
                                        return (<TableCell key={dateIndex} className={styles.rfValueTotals} sx={{ flexGrow: 1, border: "1px solid grey", padding: "4px" }} align={"center"}>{resourceForecast.timeOffLineData.resourceForecastLineAllocationDataMap[dateString]}</TableCell>)
                                    }
                                    return (<TableCell key={dateIndex} sx={{ flexGrow: 1, border: "1px solid grey", padding: "4px" }}></TableCell>)
                                })
                            }
                        </TableRow>
                        {/* empty row*/}
                        <TableRow><TableCell colSpan={7} sx={{ border: 0 }}></TableCell></TableRow>
                        {/* Month Year Row*/}
                        <TableRow>
                            <TableCell colSpan={7} sx={{ flexGrow: 1, border: "1px solid grey", lineHeight: "1px" }}></TableCell>
                            {
                                resourceForecast.dateColumns.map((dateString: string, dateIndex: number) => {
                                    const MonthYearKey = "01" + dateString.substring(4, 6) + dateString.substring(0, 4);
                                    if (currMonthYearKey.current !== MonthYearKey) {
                                        const oDate: DateTime = DateTime.fromFormat(MonthYearKey, "ddMMyyyy");
                                        currMonthYearKey.current = MonthYearKey; 
                                        return (
                                            <TableCell key={dateIndex} colSpan={resourceForecast.dateColumnsMonthSpan[MonthYearKey]} sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#e5e5e5", padding: "4px" }} align={"center"}>
                                                {oDate.toFormat("MMM") + " " +  oDate.toFormat("yyyy") }
                                            </TableCell>
                                        )
                                    }
                                })
                            }
                        </TableRow>
                    </TableHead>
                }
                <TableBody>
                    {
                                resourceForecast?.resourceForecastLines.map((forecastLine: IResourceForecastLineData, lineIndex: number) => {
                                    // Limit length of Task Description
                                    let idxOfTaskSpace = +0;
                                    let taskDesc = forecastLine.resourcePlanLineReferenceDescriptor.indexOf('(') > 0 ? forecastLine.resourcePlanLineReferenceDescriptor.substring(0, forecastLine.resourcePlanLineReferenceDescriptor.indexOf('(')).replace("PS Implementation > ", "").replace("INTEGRATIONS > ", "").replace("Non-Billable > ", "") : "";

                                    if (taskDesc.length > 40) { idxOfTaskSpace = taskDesc.indexOf(" ", 40); }
                                    if (idxOfTaskSpace >= 40) { taskDesc = taskDesc.substring(0, idxOfTaskSpace) + "\n" + taskDesc.substring(idxOfTaskSpace + 1, taskDesc.length - idxOfTaskSpace - 1); }

                                    // Changing font color based on totals < 0
                                    let availableHours = forecastLine.budgetedHours - forecastLine.hoursBurned;
                                    let forecastedHours = calculateForecastedHours(forecastLine);
                                    let remainingHours = calculateRemainingHours(forecastLine);
                                    let availColor = ((availableHours) < 0) ? "red" : "black";
                                    let remainingColor = ((remainingHours) < 0) ? "red" : "black";
                                    let errorStatus = "";
                                    let saveStatus = "";
                                    // Limit length on Project Name and check for add (fixed bid)
                                    let projectNameStr = forecastLine.projectName;
                                    let idxOfSpace = +0;
                                    if (projectNameStr.length > 40) { idxOfSpace = projectNameStr.indexOf(" ", 40); }
                                    if (idxOfSpace > 40) { projectNameStr = projectNameStr.substring(0, idxOfSpace) + "\n" + projectNameStr.substring(idxOfSpace + 1, projectNameStr.length); }

                                    // Change error savestatus 
                                    if (forecastLine.errorUpdatingRF) {
                                        errorStatus = " [ *** ERROR OCCURRED SAVING *** ] - ";
                                    }
                                    if (forecastLine.isFixedBid || forecastLine.isTandM) { projectNameStr = projectNameStr + "\n" + "(" + (forecastLine.isFixedBid ? "Fixed Bid" : "") + (forecastLine.isTandM ? "Time & Materials" : "") + ")"; }
                                    if (forecastLine.isProjectUpdating) { saveStatus += "\n|Refreshing Project| "; }
                                    if (forecastLine.isSavePending) { saveStatus += "\n |Save Pending| " + errorStatus; }
                                    // Last Updated Time format change
                                    let lastUpdatedTime = DateTime.fromISO(forecastLine.lastUpdatedDateTime).toLocaleString(DateTime.DATETIME_SHORT);
                                        return (
                                            <>
                                            {   // Put a head row every 10th row unless the last section only has 15 or less.
                                                lineIndex %10 === 0 &&
                                                <TableRow>
                                                    <TableCell sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#6A6867", color: "#fff" }} align={"center"}></TableCell>
                                                    <TableCell sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#6A6867", color: "#fff" }} align={"center"}>Project</TableCell>
                                                    <TableCell sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#6A6867", color: "#fff" }} align={"center"}>Task/Role</TableCell>
                                                    <TableCell sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#6A6867", color: "#fff" }} align={"center"} title="Estimated Hours for this task">Budgeted</TableCell>
                                                    <TableCell sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#6A6867", color: "#fff" }} align={"center"} title="Hours still available from estimate (Estimated - Burned)">Available</TableCell>
                                                    <TableCell sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#6A6867", color: "#fff" }} align={"center"} title="Forecasted Hours">Forecasted</TableCell>
                                                    <TableCell sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#6A6867", color: "#fff" }} align={"center"} title="Hours Remaining (Available - Forecast)">Remaining</TableCell>
                                                    {
                                                        resourceForecast.dateColumnsHeaders.map((dateString: string) => {
                                                            return (
                                                                <TableCell key={dateString} sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#6A6867", color: "#fff", padding: "4px" }} align={"center"}>
                                                                    {dateString}
                                                                </TableCell>
                                                            )
                                                        })
                                                    }
                                                </TableRow>
                                            }
                                                <TableRow key={"tr" + forecastLine.resourceForecastLineID} className={lineIndex % 2 === 1 ? styles.rfValueGray : styles.rfValueWhite}>
                                                    <TableCell sx={{ flexGrow: 1, border: "1px solid grey", padding: "4px" }}>{lineIndex + 1}</TableCell>
                                                    <TableCell title={forecastLine.projectID + "-" + forecastLine.resourceForecastLineID} sx={{ flexGrow: 1, border: "1px solid grey" }}>{errorStatus} {projectNameStr}
                                                        {   // info in project name 
                                                            !forecastLine.isSavePending && !forecastLine.isProjectUpdating ?
                                                                (
                                                                    <Typography variant="subtitle1">
                                                                        <img alt="Queue Forecast" src={ProcessImage} height="16" width="16" style={{ cursor: "pointer" }} onClick={(event) => { queueForecast(forecastLine) }}></img>
                                                                        {"     "}
                                                                        <Link to={`/audithistory/${forecastLine.resourceForecastLineID}`}>
                                                                            <img alt="View History" src={CircleHistory64} height="16" width="16" style={{ cursor: "pointer" }}></img>
                                                                        </Link>
                                                                        {!forecastLine.isSavePending || !forecastLine.isProjectUpdating ? "   As of: " + lastUpdatedTime : " "}
                                                                        {saveStatus.length > 0 ? <Typography className={`${tableStyles.savePending}`}>{saveStatus}</Typography> : ""}
                                                                    </Typography>
                                                                )
                                                                : <Typography className={`${tableStyles.savePending}`}>{saveStatus}</Typography>
                                                        }
                                                    </TableCell>
                                                    <TableCell title={`${forecastLine.projectPlanTaskID ?? ""} (${DateTime.fromISO(forecastLine.taskStartDate).toLocaleString(DateTime.DATETIME_SHORT)} - ${DateTime.fromISO(forecastLine.taskEndDate).toLocaleString(DateTime.DATETIME_SHORT)})`} sx={{ flexGrow: 1, border: "1px solid grey" }}>{`${taskDesc} ${forecastLine.projectRoleReferenceID ? forecastLine.projectRoleReferenceID : ""}  ${forecastLine.resourcePlanLineReferenceDescriptor}`}</TableCell>
                                                    <TableCell sx={{ flexGrow: 1, border: "1px solid grey" }} align={"center"}>{forecastLine.budgetedHours}</TableCell>
                                                    <TableCell sx={{ flexGrow: 1, border: "1px solid grey" }} align={"center"} style={{ color: availColor }} >{availableHours.toFixed(2)}</TableCell>
                                                    <TableCell sx={{ flexGrow: 1, border: "1px solid grey" }} align={"center"}>{forecastedHours.toFixed(2)}</TableCell>
                                                    <TableCell sx={{ flexGrow: 1, border: "1px solid grey" }} align={"center"} style={{ color: remainingColor }}>{remainingHours.toFixed(2)}</TableCell>
                                                    {
                                                        resourceForecast.dateColumns.map((date: string, dateIndex: number) => {
                                                            let tdTitle: string = "Project[" + forecastLine.projectName + "] Role[" + forecastLine.projectRoleName + "] Task[" + forecastLine.taskName + "-" + forecastLine.taskDescriptor + "] savepending [" + forecastLine.isSavePending + "]";
                                                            if (dateInRange(date, forecastLine)) {
                                                                // Input based on resourceForecastLineAllocationDataMap
                                                                return (
                                                                    <TableCell key={forecastLine.resourceForecastLineID + '-' + date} title={tdTitle} sx={{ flexGrow: 1, border: "1px solid grey", padding: "4px" }}>
                                                                        <input id={forecastLine.resourceForecastLineID + '-' + date} name={forecastLine.resourceForecastLineID + '-' + date} onWheel={numberInputOnWheelPreventChange} className={styles.rfValue} type="number" value={forecastLine.resourceForecastLineAllocationDataMap[date]} onChange={(event) => updateForecastedHours(forecastLine, date, Number(event.target.value))} />
                                                                    </TableCell>
                                                                )
                                                            }
                                                            else {
                                                                // Forecast for a specific date that is out of range but still required to show
                                                                if (forecastLine.resourceForecastLineAllocationDataMap[date]) {
                                                                    return (
                                                                        <TableCell key={forecastLine.resourceForecastLineID + '-' + date} title="" sx={{ flexGrow: 1, border: "1px solid grey", padding: "4px" }}>
                                                                            <input disabled id={forecastLine.resourceForecastLineID + '-' + date} name={forecastLine.resourceForecastLineID} onWheel={numberInputOnWheelPreventChange} className={styles.rfValue} type="number" defaultValue={forecastLine.resourceForecastLineAllocationDataMap[date]} />
                                                                        </TableCell>
                                                                    )
                                                                }
                                                                return (<TableCell key={forecastLine.resourceForecastLineID + '-' + date} className={styles.rfEmptyCell} sx={{ flexGrow: 1, border: "1px solid grey", padding: "4px" }}></TableCell>)
                                                            }
                                                        })
                                                    }
                                                </TableRow>
                                        </>
                                    )
                        })
                    }
                </TableBody>
                </Table>
            </TableContainer>
            <Stack spacing={2} direction="row" sx={{ padingTop: 4 }}>
                <Button key="save" variant="contained" className={tableStyles.buttonColor} size="large" onClick={async() => { await saveForecast.mutate()}}>Save</Button>
                <Button key="refresh" variant="contained" size="large" onClick={async () => { await refreshClick() }}>Refresh Forecasts</Button>
            </Stack>
        </ThemeProvider>
        </>
    )
}

export default Forecast;

                        