import { useNavigate } from "react-router-dom"
import { useMsal } from "@azure/msal-react"

import PageLayout from './components/PageLayout'
import AppRouter from "./routers/AppRouter"
import { CustomNavigationClient } from './util/NavigationClient'

const App = () => {
    const { instance } = useMsal();
    const history = useNavigate();
    const navigationClient = new CustomNavigationClient(history);
    instance.setNavigationClient(navigationClient);

    return (
        <PageLayout>
            <AppRouter />
        </PageLayout>
    )
}

export default App;