import { Configuration, LogLevel, PopupRequest, RedirectRequest } from '@azure/msal-browser';

const getClientId = (): string => {
    let clientId = '';
    
    let env = process.env.test_variable;
    console.log('env:', env);
    console.log('wlh:', window.location.host);
    if (window.location.host.toLowerCase().includes("dev") || window.location.host.toLowerCase().includes("localhost") || env === "forecast-dev") {
        clientId = '4a223dce-ae15-4442-8c56-520d688ea237' // Forecast-Tracker-Client-Dev
    }
    else {
        clientId = 'f7ef0857-7071-4965-8b75-c21660edc07c';
    }
    return clientId;
}

export const msalConfig: Configuration = {
    auth: {
        clientId: getClientId(), // Forecast Tracker App Registrations
        authority: 'https://login.microsoftonline.com/c8090595-d8cb-4482-8ff3-0fea87eea80b',
        // redirectUri: '/',
        // postLogoutRedirectUri: '/',
        navigateToLoginRequestUrl: false
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        //console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
        },
    }
};

const getServerName = (): string => {
    let clientId = '';

    let env = process.env.REACT_APP_AZURE_ENV;
    if (window.location.host.toLowerCase().includes("dev") || window.location.host.toLowerCase().includes("localhost") || env === "forecast-dev") {
        return "Forecast-Tracker-Server-Dev"
    }
    else {
        return "Forecast-Tracker-v2-Server-Prod";
    }
}

export const forecastServerLoginRequest: RedirectRequest = {
    scopes: ["api://" + getServerName() + "/Read.All"]
}

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit: 
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const graphLoginRequest: PopupRequest = {
    scopes: ["User.Read"]
};


/**
 * An optional silentRequest object can be used to achieve silent SSO
 * between applications by providing a "login_hint" property.
 */
export const silentRequest = {
    scopes: ["openid", "profile"],
    loginHint: "example@domain.net"
};


