import { useContext, useEffect, useState } from 'react';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { Grid, Checkbox, Box, Typography, FormControlLabel } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { forecastServerLoginRequest } from '../util/AuthConfig';
import { ImpersonatedUserContext } from "../contexts/ImpersonatedUserContext";
import { IExtendedEmployee } from '../models/IEmployee';
import { IProjectImpl } from '../models/IProjectImpl';
import styles from '../styles/home-styles.module.css';
import BudgetImage from '../images/budget.png';
import PreviewImage from '../images/preview.png';
import { Link } from 'react-router-dom';
import Loading from '../components/Loading';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const HomePage = (): JSX.Element => {
    const { instance, inProgress } = useMsal();
    const { execute } = useFetchWithMsal({
        scopes: forecastServerLoginRequest.scopes
    });
    const [projects, setProjects] = useState<IProjectImpl[]>([]); // Projects to be displayed in the table 
    const { impersonatedUser } = useContext(ImpersonatedUserContext);
    const [loading, setLoading] = useState(false);
    const [zeroFlag, setZeroFlag] = useState(false);
    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        const zflag = localStorage.getItem('zeroflag');
        if (zflag) {
            setZeroFlag(zflag==='true'?true:false);
        }
    }, []);

    useEffect(() => {
        const init = async() => {
            if (inProgress === InteractionStatus.None && isAuthenticated) {
                setLoading(true)
                // make request to api to see if there's already an impersonated user for this session
                const signedInUserEmail: string | undefined = instance.getActiveAccount()?.username
                if (!signedInUserEmail) {
                    toast.success("Signed in user cannot be null");
                    throw new Error('Signed in user cannot be null')
                }

                // Sync Impersonated User
                await execute<IExtendedEmployee>('POST', 'api/Home/SyncImpersonatedUser', { SignedInUserEmail: signedInUserEmail, ImpersonatedUserId: impersonatedUser?.id ?? null })

                const projectList: IProjectImpl[] | null = await execute<IProjectImpl[]>('POST', 'api/Home/GetProjectsList')
                setProjects(projectList ?? [])
                setLoading(false)
            }
        }

        init();
    }, [execute, instance, inProgress, impersonatedUser?.id])

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
            <Grid container marginTop={2} spacing={4} flexDirection='column' justifyContent='normal'>
                { loading && <Loading paddingValue={4} /> }
                {!loading && projects.length > 0 &&
                    <Grid item>
                        
                        <Link to={`/forecast/${zeroFlag}`} className={`${styles.forecastLinkStyles}`}>Forecast For All Projects</Link><br/>
                        <FormControlLabel style={{ fontSize: '0.875rem' }} control={
                            <Checkbox checked={zeroFlag} title={zeroFlag.toString()} onChange={() => {
                                localStorage.setItem('zeroflag', (!zeroFlag).toString());
                                setZeroFlag(!zeroFlag);
                            }} />}
                            label={<span style={{ fontSize: '0.875rem' }}> Include Zero Hour Forecasts </span>}   />
                    </Grid>
                }
                <Grid item>
                    {!loading && projects.length === 0 && 
                        <Box>
                            <Typography variant='h4'>
                                No Projects found!
                            </Typography>  
                        </Box>
                    }
                    {!loading && projects.length > 0 &&
                        <TableContainer component={Paper} sx={{ paddingLeft: 0, paddingRight: 0, boxShadow: 5 }}>
                            <Table sx={{ minWidth: 600 }} aria-label="simple table" size={'small'}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={`${styles.myProjectHeaderStyles}`}>Budget</TableCell>
                                        <TableCell className={`${styles.myProjectHeaderStyles}`}>EAC</TableCell>
                                        <TableCell className={`${styles.myProjectHeaderStyles}`}>Project Name</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                
                                    {projects?.map((project: IProjectImpl, index: number) => ((zeroFlag || project.numberOfForecastHours > 0) &&
                                        <TableRow key={index}>
                                            {
                                                project.canModifyBudget && project.isProjectBudgeted ?
                                                    <TableCell className={`${styles.projectDetails}`} align="center">
                                                        <Link to={`/budget/${project.projectID}`} color="inherit">
                                                            <img src={BudgetImage} height='14' alt="New Project Budget"></img>
                                                        </Link>
                                                    </TableCell>
                                                    : <TableCell sx={{ width: "2%" }}></TableCell>
                                            }
                                            <TableCell className={`${styles.projectDetails}`} align="center">
                                                <Link to={`/eac/${project.projectID}`}>
                                                    <img src={PreviewImage} height='14' alt="EAC Report"></img>
                                                </Link>
                                            </TableCell>
                                        
                                            <TableCell>
                                                <Link to={`/forecast/${zeroFlag}/${project.projectID}`} className={`${styles.linkStyles}`}>
                                                    {project.projectName}
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                </Grid>
            </Grid>
        </>
    ) 
}
export default HomePage;