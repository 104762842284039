import { Box, LinearProgress } from "@mui/material";
interface ILoading {
    paddingValue: number
}
export const Loading = (props: ILoading) => {

    return (
        <Box id="loadingBox" sx={{ width: '98%', paddingLeft: props.paddingValue }}>
            <LinearProgress id="linearProgress" />
        </Box>
    );
}

export default Loading;