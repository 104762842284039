import React, { useState, useContext } from 'react';
import { useMsal } from '@azure/msal-react';
import { EndSessionRequest } from '@azure/msal-browser';

import { Avatar, Box, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material';
import { Logout } from '@mui/icons-material';

import { GraphProfileContext } from '../contexts/GraphProfileContext';

import { ImpersonatedUserContext } from "../contexts/ImpersonatedUserContext";
import { forecastServerLoginRequest, graphLoginRequest } from "../util/AuthConfig";
import useFetchWithMsal from "../hooks/useFetchWithMsal";
import { IExtendedEmployee } from "../models/IEmployee";

const AvatarMenu: React.FunctionComponent<any> = () => {
    const { instance } = useMsal();
    const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
    const open = Boolean(menuAnchor);
    const profile = useContext(GraphProfileContext);
    const { setImpersonatedUser } = useContext(ImpersonatedUserContext);
    const { execute } = useFetchWithMsal({
        scopes: forecastServerLoginRequest.scopes
    });

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMenuAnchor(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuAnchor(null);
    }

    const setPhoto = (): string => {
        const url = window.URL || webkitURL;
        return profile.photo ? url.createObjectURL(profile.photo) : ""
    }

    const logout = async () => {
        setImpersonatedUser(null);
        const signedInUserEmail = instance.getActiveAccount()?.username;
        await execute<IExtendedEmployee>('POST', '/api/Home/EndUserSession');

        const logoutRequest: EndSessionRequest = {
            account: instance.getActiveAccount()
        }

        await instance.logoutRedirect(logoutRequest);
    }

    return (
        <Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', alignItems: 'center'}} onClick={handleMenuOpen}>
                <Avatar alt={profile.displayName} src={setPhoto()} sx={{mr:2 }} />
                <Box sx={{flexDirection: 'column'}}>
                    <Typography variant="subtitle1">Signed in as: {profile.displayName}</Typography>
                </Box>
            </Box>            
            <Menu open={open} anchorEl={menuAnchor} onClose={handleMenuClose}>
                <MenuItem onClick={logout}>                
                    <ListItemIcon>
                        <Logout fontSize='medium' />
                    </ListItemIcon>
                    <ListItemText>
                        Logout
                    </ListItemText>
                </MenuItem>
            </Menu>
        </Box>
    );
};

export default AvatarMenu;