import { Routes, Route } from "react-router-dom"
import HomePage from "../pages/Home"
import Budget from "../pages/Budget"
import EAC from "../pages/EAC"
import Forecast from "../pages/Forecast"
import Security from "../pages/Security"
import AuditHistory from "../pages/AuditHistory"

export const AppRouter = () => {

    return (
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="security" element={<Security />} />
            {/* May need to eventually go inside on the route above, not sure. */}
            <Route path="budget/:id" element={<Budget />} />
            <Route path="eac/:id" element={<EAC />} />

            {/* Make id param optional, that way we can display all projects if not passed in */}
            <Route path="forecast/:zeroFlag/:id?" element={<Forecast />} /> 
            <Route path="auditHistory/:id" element={<AuditHistory />} />
    </Routes>
    )
} 

export default AppRouter;