import { StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { MsalProvider } from "@azure/msal-react"
import { PublicClientApplication, EventType, AuthenticationResult, EventMessage } from "@azure/msal-browser"

import App from './App';
import reportWebVitals from './reportWebVitals';
import { msalConfig } from './util/AuthConfig'

import './styles/home.css';

// https://github.com/AzureAD/microsoft-authentication-library-for-js/tree/a81fcef3d82523e03828d91bb0ee8d2ab2cc20d8/samples/msal-react-samples/typescript-sample
const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    console.info('setting active account...')
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.enableAccountStorageEvents();

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        console.info('MSAL Login Success')
        msalInstance.setActiveAccount(payload.account);
    }
});

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

root.render(
    // IMPORTANT: Removed React.StrictMode and <StrictMode> due to issues with the useMsal hook notated here:
    //            https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/5468
        <BrowserRouter>
            <MsalProvider instance={msalInstance}>
                <App />
            </MsalProvider>
        </BrowserRouter>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
