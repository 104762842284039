import { useState, useMemo } from "react";
import { Grid } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ISecurity } from "../models/ISecurity";
import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import useFetchWithMsal from "../hooks/useFetchWithMsal";
import { forecastServerLoginRequest } from "../util/AuthConfig";
import ErrorPage from "./Error";

import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { IExtendedEmployee } from "../models/IEmployee";
import { Save } from "@mui/icons-material";
import Loading from "../components/Loading";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const Security = () => {
    const queryClient = useQueryClient();
    const { execute } = useFetchWithMsal({
        scopes: forecastServerLoginRequest.scopes
    })
    const { instance, inProgress } = useMsal();

    const useSecurity = () => {
        return useQuery<ISecurity | null>({
            queryKey: ['security', { instance, inProgress }],
            queryFn: async({ queryKey }) => {
                const [_key, { instance: localInstance }] = queryKey as any
                if (inProgress === InteractionStatus.None) {
                    const signedInUserEmail: string | undefined = localInstance.getActiveAccount()?.username;
                    if (!signedInUserEmail) {
                        throw new Error('No signed in user found.')
                    }

                    // pass in impersonated user, and check on the server side if the session matches this?
                    // if not, maybe throw an error?
                    const sec = await execute<ISecurity>('POST', 'api/Home/GetAppSecurity', signedInUserEmail);
                    console.table(sec)

                    if (!sec) {
                        toast.error("Coule not retrieve security.");
                        throw new Error("Could not retrieve security.")
                    }

                    return sec;
                }

                return null;
            },
            refetchOnWindowFocus: false, // keeps the query from refetching if you switch tabs
        })
    }
    const { data, isPending, isFetching, error } = useSecurity();

    const updateSubordinateUsersMutation = useMutation({
        mutationFn: async (idTokenString: string) => await execute('POST', 'api/Home/SetAppSecurity', idTokenString),
        onSuccess:
            () => {
                queryClient.invalidateQueries({ queryKey: ['security'] });
                toast.success("Set App Security!");
            }
            
    })

    if (isPending || isFetching) {
        return <Loading paddingValue={4} />
    }

    if (error) {
        return <ErrorPage error={error} />
    }

    const RemoveNonPrimitives = (): OmittedEmployee[] => {
        return data?.empList.map((a: IExtendedEmployee) => {
                return {
                    id: a.id,
                    ntid: a.ntid,
                    wid: a.wid,
                    firstName: a.firstName,
                    lastName: a.lastName,
                    fullUserName: a.fullUserName,
                    authType: a.authType
                } as OmittedEmployee
            }
        ) || []
    }

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
            <Grid container marginTop={2} spacing={4} flexDirection='column' justifyContent='normal'>
                <Grid item>
                    {data && <EnhancedTable rows={RemoveNonPrimitives()} selectedRows={data?.idsSelected} 
                        updateHandler={updateSubordinateUsersMutation}
                    />}
                </Grid>
            </Grid>
        </>
    )
}

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  type Order = 'asc' | 'desc';
  
  function getComparator<Key extends keyof any>(order: Order, orderBy: Key): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
  ) => number {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  // Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
  // stableSort() brings sort stability to non-modern browsers (notably IE11). If you
  // only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
  // with exampleArray.slice().sort(exampleComparator)
  function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number): T[] {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order: number = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  
  interface HeadCell<T> {
    disablePadding: boolean;
    id: keyof T;
    label: string;
    numeric: boolean;
  }
  
  const headCells: readonly HeadCell<OmittedEmployee>[] = [
    {
        id: 'id',
        numeric: false,
        disablePadding: false,
        label: 'Employee ID',
    },
    {
        id: 'fullUserName',
        numeric: false,
        disablePadding: true,
        label: 'Employee Name',
    },
  ];

  
  interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof OmittedEmployee) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
  }
  
  function EnhancedTableHead(props: EnhancedTableProps) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property: keyof OmittedEmployee) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  
  interface EnhancedTableToolbarProps {
    numSelected: number;
    tableTitle: string;
    updateHandler: any;
    selected: readonly string[];
  }
  
  // TODO:
  // INTEGRATE THIS: https://mui.com/material-ui/react-progress/#interactive-integration
  function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
    const { numSelected, tableTitle, updateHandler, selected } = props;
  
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: '1 1 100%' }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {tableTitle}
          </Typography>
        )}
        {numSelected > 0 ? (
          <Tooltip title="Save">
            <IconButton
                onClick={async() => await updateHandler.mutate(selected.join(','))}
            >
              <Save />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton>
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </Toolbar>
    );
  }


  interface ITableProps {
    rows: OmittedEmployee[];
    selectedRows: readonly string[];
    updateHandler: any;
  }

  type OmittedEmployee = Omit<IExtendedEmployee, "isTerminated" | "terminationDate">;

  export const EnhancedTable = (props: ITableProps) => {
    const { rows, selectedRows, updateHandler } = props;

    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof OmittedEmployee>('id');
    const [selected, setSelected] = useState<readonly string[]>(selectedRows);
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(true);
    const [rowsPerPage, setRowsPerPage] = useState(25);
  
    const handleRequestSort = (
      event: React.MouseEvent<unknown>,
      property: keyof OmittedEmployee,
    ) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };
  
    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        const newSelected = rows.map((n: OmittedEmployee) => n.id);
        setSelected(newSelected);
        return;
      }
      setSelected([]);
    };
  
    const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
      const selectedIndex: number = selected.indexOf(id);
      let newSelected: readonly string[] = [];
  
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } 
      else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } 
      else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } 
      else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }
      setSelected(newSelected);
    };
  
    const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
  
    const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
      setDense(event.target.checked);
    };
  
    const isSelected = (id: string) => selected.indexOf(id) !== -1;
  
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  
    const visibleRows = useMemo(
      () =>
        stableSort<OmittedEmployee>(rows, getComparator(order, orderBy)).slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage,
        ),
      [order, orderBy, page, rows, rowsPerPage],
    );
  
    return (
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <EnhancedTableToolbar 
            numSelected={selected.length} 
            tableTitle="App Security" 
            updateHandler={updateHandler}
            selected={selected}
            />
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
  
                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.id}
                      </TableCell>
                      <TableCell align="left">{row.fullUserName}</TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[25, 50, 75, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Dense padding"
        />
      </Box>
    );
  }

export default Security;