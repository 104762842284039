import * as React from "react";
import { useState, useEffect, useCallback } from 'react';
import { Link as RouterLink } from "react-router-dom";

import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus, InteractionRequiredAuthError, AccountInfo } from "@azure/msal-browser";

import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, Container, Grid, IconButton, Link, Menu, MenuItem, Toolbar } from "@mui/material";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import AvatarMenu from './AvatarMenu';
import SignInSignOutButton from "./LoginButtons";
import { forecastServerLoginRequest, graphLoginRequest } from "../util/AuthConfig"
import { GraphService, IProfile } from "../util/GraphService";
import { ImpersonatedUserContext } from "../contexts/ImpersonatedUserContext";
import { GraphProfileContext } from "../contexts/GraphProfileContext";
import { IExtendedEmployee } from "../models/IEmployee";
import useFetchWithMsal from "../hooks/useFetchWithMsal";
import VirtualizedSelector from "./VirtualizedSelector";
import { ISignedInUser } from "../models/ISignedInUser";

const queryClient = new QueryClient();

interface IPages {
    children: React.ReactNode;
}

const PageLayout: React.FunctionComponent<any> = (props: IPages) => {
    const { children } = props;

    const { inProgress, instance, accounts } = useMsal();
    const [graphProfile, setGraphProfile] = useState<IProfile>({ photo: undefined, displayName: 'unknown', email: 'unknown' });
    const [impersonatedUser, setImpersonatedUser] = useState<IExtendedEmployee | null>(null);
    const [navAnchor, setNavAnchor] = useState<null | HTMLElement>(null);
    const [loaded, setLoaded] = useState(false);
    const open = Boolean(navAnchor);
    const { execute } = useFetchWithMsal({
        scopes: forecastServerLoginRequest.scopes
    });
    const [signedInUserData, setSignedInUserData] = useState<ISignedInUser | null>(null);
    const isAuthenticated = useIsAuthenticated();
    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setNavAnchor(event.currentTarget);
    };
    
    const handleClose = () => {
        setNavAnchor(null);
    };

    // useCallback returns a memoized version of the function because this function
    // makes the dependencies of the useEffect hook change on every render
    const setProfileData = useCallback(async() => {
        try {
            //console.log('attempting to get Graph Profile...');
            if (inProgress === InteractionStatus.None && isAuthenticated && instance) {
                console.log('Querying and setting profile data...')
                const prof = await GraphService.GetProfile(instance, accounts);
                const signedInUserEmail: string | undefined = instance.getActiveAccount()?.username;

                // load signed in user's settings
                // important: using a '/' in front on the api prevents react-router from making the api call relative to the current location
                const signedInUserSettings: ISignedInUser | null = await execute<ISignedInUser>('POST', '/api/Home/LoadUserSettings', signedInUserEmail);
                console.info('Using WD Env: ' + signedInUserSettings?.workdayEnvironment ?? "Not Sandbox");

                const sessionImpersonatedUser: IExtendedEmployee | null = await execute<IExtendedEmployee>('POST', '/api/Home/SyncImpersonatedUser', { SignedInUserEmail: signedInUserEmail, ImpersonatedUserId: impersonatedUser?.id ?? null });

                setImpersonatedUser(sessionImpersonatedUser)
                setSignedInUserData(signedInUserSettings)

                setGraphProfile(prof);
                setLoaded(true);
            }
        }
        catch (e) {
            if (e instanceof InteractionRequiredAuthError)
            {
                instance.acquireTokenRedirect({
                    ...graphLoginRequest,
                    account: instance.getActiveAccount() as AccountInfo
                });
            }
            console.log(e);
        }
    }, [accounts, instance, execute, impersonatedUser?.id, isAuthenticated]);

    useEffect(() => {
        const getProfileDataAsync = async() => {
            if (!graphProfile.photo && inProgress === InteractionStatus.None) {
                try {
                    await setProfileData();
                }
                catch (err) {
                    console.table(err);
                }
            }
        }

        getProfileDataAsync();
    }, [inProgress, graphProfile, setProfileData]);

    

    return (
        <>
            <AuthenticatedTemplate>
                <QueryClientProvider client={queryClient}>
                    <GraphProfileContext.Provider value={graphProfile}>
                        <ImpersonatedUserContext.Provider value={{ impersonatedUser, setImpersonatedUser }}>
                            <AppBar position="sticky" color="primary">
                                <Toolbar sx={{ backgroundColor: "#6AA2DC" }}>
                                    <IconButton size="large" edge="start" color="inherit" aria-label="open drawer" sx={{ mr: 2 }} onClick={handleMenuClick}>
                                        <MenuIcon />
                                    </IconButton>
                                    <Menu id="nav-menu" anchorEl={navAnchor} open={open} onClose={handleClose} MenuListProps={{ 'aria-labelledby': 'basic-button' }} >
                                        <MenuItem onClick={handleClose}><Link to='/' component={RouterLink} underline='none' >Home</Link></MenuItem>
                                        <MenuItem onClick={handleClose} disabled={!signedInUserData?.hasAccessSecurity}><Link to='/security' component={RouterLink} underline='none' >Security</Link></MenuItem>
                                    </Menu>
                                    <Link sx={{ flexGrow: 1, textAlign: 'center', width: 1 / 2 }} component={RouterLink} to='/' underline='none' color='inherit' variant='h4' > Cognizant Forecast Tracker {signedInUserData?.workdayEnvironment ?? "" }</Link>
                                    <VirtualizedSelector options={signedInUserData?.subEmployeeList ?? []} />
                                    <AvatarMenu />
                                </Toolbar>
                            </AppBar>
                            <main>
                                <Container maxWidth={false} id="containerChildren">
                                    {
                                        <Container maxWidth={false} id="containerVirtualizerEmpty" sx={{ paddingTop: "1.5%" }}></Container>
                                    }
                                    {loaded && children}
                                </Container>
                            </main>
                        </ImpersonatedUserContext.Provider>
                    </GraphProfileContext.Provider>
                    <ReactQueryDevtools initialIsOpen={true} />
                </QueryClientProvider>
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <Box>
                    <Grid container justifyContent="center">
                        <h1>Not Authenticated</h1>
                    </Grid>
                    <Grid container justifyContent="center">
                         <SignInSignOutButton />
                    </Grid>
                </Box>
            </UnauthenticatedTemplate>
        </>
    );
};

export default PageLayout;