import { useCallback } from 'react';
import { InteractionType, RedirectRequest } from '@azure/msal-browser';
import { useMsal, useMsalAuthentication } from "@azure/msal-react";

type Method = 'GET' | 'POST' | 'PUT' | 'DELETE';

/**
 * Custom hook to call a web API using bearer token obtained from MSAL
 * @param {RedirectRequest} msalRequest 
 * @returns 
 */

const useFetchWithMsal = (msalRequest: RedirectRequest) => {
    const { instance } = useMsal();
    const { result, error: msalError } = useMsalAuthentication(InteractionType.Redirect, {
        ...msalRequest,
        account: instance.getActiveAccount() ?? undefined,
        // prompt: 'select_account' tried to force a login for testing
        // redirectUri: window.origin // or /redirect?
    });

    /**
     * Execute a fetch request with the given options
     * @param {string} method: GET, POST, PUT, DELETE
     * @param {String} endpoint: The endpoint to call
     * @param {Object} data: The data to send to the endpoint, if any 
     * @returns JSON response
     */

    const execute = async<T>(method: Method, endpoint: string, data: any | null = null): Promise<T | null> => {
        if (msalError) {
            console.info(msalError);
            throw new Error(JSON.stringify(msalError));
            return null;
        }
        
        if (!result) {
            //console.log('NO MSAL AUTH RESULT')
            return null;
            // throw new Error('MSAL still resolving...')
        }

        const headers = new Headers();
        const bearer = `Bearer ${result.accessToken}`;
        headers.append("Authorization", bearer);
        
        if (data) headers.append('Content-Type', 'application/json');
        
        let options: RequestInit = {
            method: method,
            headers: headers,
            body: data ? JSON.stringify(data) : null,
        };

        let response: Response = await fetch(endpoint, options);

        if (response.status !== 200) {
            return null as T;
        }

        let responseData: any = await response.json(); 
        if (!response) {
            throw new Error(responseData)
        }
        return responseData as T;
    };

    return {
        execute: useCallback(execute, [result, msalError]), // to avoid infinite calls when inside a `useEffect`
    };
};

export default useFetchWithMsal;