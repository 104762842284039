import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import useFetchWithMsal from "../hooks/useFetchWithMsal";

import ErrorPage from "./Error";
import { forecastServerLoginRequest } from "../util/AuthConfig";
import Loading from "../components/Loading";
import { useMsal } from "@azure/msal-react";
import IForecastAuditUI from "../models/IForecastAuditUI";
import { DateTime } from "luxon";
import IForecastAuditLineUI from "../models/IForecastAuditLineUI";
import styles from '../styles/home-styles.module.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const AuditHistory = () => {
    const { id } = useParams();
    const { execute } = useFetchWithMsal({ scopes: forecastServerLoginRequest.scopes });
    const { instance, inProgress } = useMsal();

    const useAuditHistory = () => {
        let resourceForecastLineID = id;
        return useQuery<IForecastAuditUI | null>({
            queryKey: ['audithistory', { instance, inProgress }],
            queryFn: async ({ queryKey }) => {
                const [_key, { instance: localInstance }] = queryKey as any
                const auditHistory = await execute<IForecastAuditUI | null>('POST', '../api/Home/GetResourceForecastAuditHistory', resourceForecastLineID);
                if (!auditHistory) {
                    toast.error("Could not retrieve Audit History.");
                    throw new Error("Could not retrieve Audit History")
                }
                return auditHistory;
            },
            refetchOnWindowFocus: false, // keeps the query from refetching if you switch tabs
        })

    }

    const { data, isPending, isFetching, error } = useAuditHistory();

    if (isPending || isFetching) {
        return <Loading paddingValue={4} />  
    }

    if (error) {
        return <ErrorPage error={error} />
    }

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
            <Paper sx={{ overFlowX: 'auto', marginTop: 4 }}>
                <Box>
                    <Typography variant='h4'>
                        {id}
                    </Typography>
                </Box> 
                {data && <EnhancedTable forecastAudits={data} />}
            </Paper>
        </>
    )
}

export default AuditHistory;

interface ITableProps {
    forecastAudits: IForecastAuditUI
}

export const EnhancedTable = (props: ITableProps) => {
    const { forecastAudits } = props;

    return (
        forecastAudits.dateColumnsMonthList.length > 0 ?
            <TableContainer>
                <Table id="auditHistory" stickyHeader sx={{ flexGrow: 1, border: "1px solid grey", borderCollapse: "collapse" }}>
                    <TableHead>
                        <TableRow key={"h0"}>
                            <TableCell colSpan={1} rowSpan={2} sx={{ flexGrow: 1, border: "1px solid grey", textWrap: "nowrap", backgroundColor: "#e5e5e5" }}>Updated By</TableCell>
                            <TableCell colSpan={2} rowSpan={2} sx={{ flexGrow: 1, border: "1px solid grey", textWrap: "nowrap", backgroundColor: "#e5e5e5", textAlign: "center" }}>Updated Date</TableCell>
                            {
                                forecastAudits.dateColumnsMonthList.map((dateString: string, dateIndex: number) => {
                                    const oDate: DateTime = DateTime.fromFormat(dateString, "ddMMyyyy");
                                    return (
                                        <TableCell key={dateIndex} colSpan={forecastAudits.dateColumnsMonthSpan[dateString]} sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#e5e5e5", lineHeight: .25, textWrap: "nowrap" }} align={"center"}>{`${oDate.toFormat("MMM")} ${oDate.toFormat("yyyy")}`}</TableCell>
                                    )
                                })
                            }

                        </TableRow>
                        <TableRow key={"h1"}>
                            {
                                forecastAudits.dateColumnsHeaders.map((dateString: string, dateIndex: number) => {
                                    return (
                                        <TableCell key={dateIndex} colSpan={forecastAudits.dateColumnsMonthSpan[dateString]} sx={{ flexGrow: 1, border: "1px solid grey", backgroundColor: "#6A6867", color: "#fff", textWrap: "nowrap" }} align={"center"}>{dateString}</TableCell>
                                    )
                                })
                            }

                        </TableRow>
                        {
                            forecastAudits.auditLineList.map((auditLine: IForecastAuditLineUI, dateIndex: number) => {
                                return (
                                    <TableRow key={dateIndex}>
                                        <TableCell colSpan={1} sx={{ flexGrow: 1, border: "1px solid grey" }}>{auditLine.updatedBy}</TableCell>
                                        <TableCell colSpan={2} sx={{ flexGrow: 1, border: "1px solid grey", textWrap: "nowrap" }} align={"center"}>{auditLine.updatedDttm}</TableCell>
                                        {
                                            forecastAudits.dateColumns.map((dateString: string, dateIndex: number) => {
                                                if (auditLine.alloc[dateString]) {
                                                    // TODO: class stuff here
                                                    return (
                                                        <TableCell key={dateIndex + dateString} className={auditLine.alloc[dateString].isChanged ? styles.rfValueGray : styles.rfValueWhite} sx={{ flexGrow: 1, border: "1px solid grey", textWrap: "nowrap" }}>{auditLine.alloc[dateString].hours}</TableCell>
                                                    )
                                                }
                                                return (
                                                    <TableCell key={dateIndex + dateString}  sx={{ flexGrow: 1, border: "1px solid grey", textWrap: "nowrap" }}></TableCell>
                                                )
                                            })
                                        }
                                    </TableRow>
                                )     
                            })
                        }
                    </TableHead>
                    <TableBody>
                    </TableBody>
                </Table>
            </TableContainer >
            :
            <Box>
                <Typography variant='h4'>
                    No Audit History !
                </Typography>  
            </Box>
    )
}